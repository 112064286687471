$zeture-topbar: 303E4C;/**c8c8ed;*/

.branding-zeture {
  .topbar {
    background-color: #303E4C;
   /* box-shadow: 0 0 35px 5px #eef5f9 inset, 5px 0 10px rgba(0, 0, 0, 0.1);*/
    /*background: linear-gradient(45deg, $zeture-topbar, white 50%, $zeture-topbar);*/

  }
}

.branding-nagato {
  .topbar {
    box-shadow: 5px 0 10px rgba(0, 0, 0, 0.2);
  }
}